import React from 'react';
import { graphql } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../../types/Article';
import Layout, { Wrapper } from '../../layouts/Layout';
import styled from '../../styled';
import { SEO } from '../../components/SEO';
import { Footer } from '../../components/Footer';
import { Branding } from '../../components/Branding';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#ffffff';

const Subheading = styled('h2')`
  font-size: 1rem;
  line-height: 2rem;
  color: ${(p) => p.theme.palette.primary.main};
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    border-bottom: 2px solid #efefef;
  `};
`;

const StyledA = styled('a')`
  color: ${COLOR};
  border-bottom: 2px solid #efefef;
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Blogging for Devs Trends: How It Works"
        siteUrl="https://bloggingfordevs.com"
        description="Impressum"
        pathname="trends/how-it-works/"
        useTitleTemplate={false}
      />
      <ProductHuntBanner />
      <div
        style={{
          maxWidth: '650px',
          padding: '16px',
          margin: '0 auto'
        }}
      >
        <Wrapper>
          <div
            style={{
              maxWidth: '400px',
              textAlign: 'center',
              margin: '0 auto 24px'
            }}
          >
            <Branding to="/">Blogging for Devs</Branding>
            <Typography variant="h4" component="h1" gutterBottom>
              Blogging for Devs Trends: How it Works
            </Typography>
          </div>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            <Link style={{ borderBottom: '1px solid' }} to="/trends/">
              Blogging for Devs Trends
            </Link>{' '}
            is meant to be a fun way to discover excellent tech blogs, made by
            and for developers! Here's how it works.
          </Typography>
          <Subheading>What problem are we solving?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Discovering great technical blogs is difficult.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Twitter is more noise than signal. The lists you'll find on Google
            are outdated, filled with links to companies and large publications,
            and written by people who don't read technical blogs themselves. If
            any developers' blogs are included, they're usually abandoned.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Our lists are an attempt at solving these problems: Blogs on{' '}
            <Link to="/trends/" style={{ borderBottom: '1px solid' }}>
              Trends
            </Link>{' '}
            are created and recommended by developers, curated for quality, and
            constantly being re-ordered based on conversations happening on
            Twitter.
          </Typography>
          <Subheading>Our two goals with Trends</Subheading>
          <Typography
            variant="h6"
            component="ol"
            paragraph
            style={{ opacity: 0.8 }}
          >
            <li>Surface great blogs written by individual developers</li>
            <li>Inspire more developers to create discussion-worthy blogs</li>
          </Typography>
          <Subheading>How do the points work?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Points are awarded to a blog based on:
          </Typography>
          <Typography
            variant="h6"
            component="ul"
            paragraph
            style={{ opacity: 0.8 }}
          >
            <li>
              Counts the least: Retweets and likes of a tweet containing a link
              to an article on the blog
            </li>
            <li>
              Counts the most: Replies and mentions of an article on the blog by
              other people
            </li>
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            In short, we try to reward content that sparks the most discussion
            as opposed to blindly boosting based on low-effort engagement.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Our main Trends page is based on 2-day trends, and our Topics pages
            are based on 30-day trends. You can change the time periods to
            explore more recent trends.
          </Typography>
          <Subheading>How you can vote for your favorite bloggers</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Share your favorite bloggers' articles and chime in with your
            thoughts when they post something new. Simple as!
          </Typography>
          <Subheading>Are these blogs really "the best"?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Best is, at best, subjective. We hope that by combining the human
            element (curation) with an algorithmic element (sparks discussion on
            Twitter), we can create a unique, and living resource that surfaces
            different "bests" at different times.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            So far, we've also seen that this results in plenty of room at the
            top for people who are creating new stuff consistently to rise
            through the ranks.
          </Typography>
          <Subheading>Why can each blog only have two topics?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            We want each list to be as unique as possible, and give more people
            room to reach the top in topics of their focus.
          </Typography>
          <Subheading>Technical details</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            This project is built with Gatsby, Google Cloud Functions, and
            Firebase. The website rebuilds itself every hour using a GitHub
            action. Screenshots are taken via Puppeteer, both when a blog is
            added and when it feels like time for a refresh.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Data is fetched from Twitter every 15 minutes via API and stored.
            Since we currently use Twitter's free 7-day history API, it's
            possible some tweets are missed. This also means, new blogs will
            take time to rise on Topics pages given the 30-day default filter.
            However, newly submitted blogs have an immediate chance to appear on
            the Trends home page, since this uses a 2-day filter by default.
          </Typography>
          <Subheading>Questions, comments, feedback, ideas?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Share them on Twitter{' '}
            <a
              href="https://twitter.com/monicalent"
              target="_blank"
              style={{ borderBottom: '1px solid' }}
            >
              @monicalent
            </a>
            , I'd love to hear your ideas!
          </Typography>
          <Subheading>Read to discover some great blogs?</Subheading>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            <Link style={{ borderBottom: '1px solid' }} to="/trends/">
              Go back to Trends
            </Link>{' '}
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
